$(document).ready(function(){
    $('#guestbook_list').on('click', '.js-copy', function(e){
     // if ('ontouchstart' in window || navigator.maxTouchPoints){
        
    //  } else {
        e.preventDefault();
        e.stopPropagation();
        //console.log($(this));
        var target = $(this).find('.copy-link__raw').text();
        //console.log(target);

        copyTextToClipboard(target);
        $('.copy-link__helper').css('display', 'none');
        $(this).find('.copy-link__helper').show();
        return false;
    //  }
    });
});
function copyTextToClipboard(text) {
  var textArea = document.createElement("textarea");

  textArea.style.position = 'fixed';
  textArea.style.top = 0;
  textArea.style.left = 0;

  textArea.style.width = '2em';
  textArea.style.height = '2em';

  textArea.style.padding = 0;

  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';

  textArea.style.background = 'transparent';


  textArea.value = text;

  document.body.appendChild(textArea);

  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
  } catch (err) {
    console.log('Oops, unable to copy');
  }

  document.body.removeChild(textArea);
}

