$(document).ready(function(){

    $('.ext__list__item').live('click', function(e){
      e.stopPropagation();
       console.log("ext__list__item click");
      var $checkbox = $(this).find('input');
      if (e.target.nodeName != 'INPUT')
      if ($checkbox.is( ":checked" )){
          $checkbox[0].checked = false;
          //console.log("true");
      } else {
          $checkbox[0].checked = true;
          //console.log("false");
      }
    });
    $('.ext__list__item input').live('click', function(e){
      //e.preventDefault();
     // setTimeout(function(){
         // $(this)[0].checked = true;
        //  console.log('true');
     // },150)

    });
    function hideScroll(){
       $('body, html').css('overflow-y', 'hidden');
   } 
    function showScroll(){
       $('body, html').css('overflow-y', 'auto');
    }

    $('.boxplus-thumbs').live('mouseover', function(){
        $(this).find('ul').css('visibility', 'visible');
        $(this).find('.boxplus-rewind').css('visibility', 'visible');
        $(this).find('.boxplus-forward').css('visibility', 'visible');
    })
    $('.boxplus-thumbs').live('mouseout', function(){
       $(this).find('ul').css('visibility', 'hidden');
           $(this).find('.boxplus-rewind').css('visibility', 'hidden');
        $(this).find('.boxplus-forward').css('visibility', 'hidden');
    });

    $('.cboxElement, .cboxElement img, #imglist2 > li').live('click', function(){
        hideScroll();
        console.log('hide cBox')
    });
    $('#cboxOverlay, .boxplus-background, .boxplus-close').live('click', function(){
        showScroll();
        // console.log('show cboxclose');
    });

    $(document).on('click', function(event){
           if(!$(event.target).closest('#cboxClose').length) {

           } else {
               showScroll();
           }
    });
    $('.group label').on('click', function(){
       $(this).prev().prev().prev().focus();
    });
    $('.scroll-top').on('click', function(){
        var body = $("html, body");
    body.stop().animate({scrollTop:0}, 400, 'swing', function() {
    });
    });

});