function AdjustIframeHeightOnLoad() { 
    setTimeout(function(){
        console.log(document.getElementById("modal-iframe").contentWindow.document.body.scrollHeight);
        document.getElementById("modal-iframe").style.height = document.getElementById("modal-iframe").contentWindow.document.body.scrollHeight + "px"; 
    }, 600);
}
    function AdjustIframeHeight(i) { document.getElementById("modal-iframe").style.height = parseInt(i) + "px"; }

  function resizeIframe(obj) {
      var count = 00;
      setInterval(function(){
        //console.log(count);
        if (count < 20){
            obj.style.height = obj.contentWindow.document.body.offsetHeight + 'px';
            count += 1;
        }
      }, 500)

  }

(function() {
    this.Modal = function(){
        //globals
        this.closeButton = null;
        this.modal = null;
        this.overlay = null;
        this.iframe = null;
        this.video = null;
        //def option
        var defaults = {
            className: 'fade-modal',
            closeButton: true,
            content: '',
            overlay: true,
            maxWidth: '320px',
            maxHeight: '90%',
            height: '100%',
            width: '100%',
            containerTag: 'div'
        }
        
        //init user vars
        if (arguments[0] && typeof arguments[0] === 'object'){
            this.options = extendDefaults(defaults, arguments[0]);
        }
        
        this.transitionEnd = transitionSelect();
    }
    //public
    Modal.prototype.open = function(){
        //open code
        build.call(this);
        initEvents.call(this);
        
        window.getComputedStyle(this.modal).height;
        
        this.modal.className = this.modal.className +
            (this.modal.offsetHeight > window.innerHeight ?
            " z-open z-anchored" : " z-open");
        this.overlay.className = this.overlay.className + " z-open";
        
    }
    Modal.prototype.close = function(){
        var _ = this;

        // Remove the open class name
        this.modal.className = this.modal.className.replace(" z-open", "");
        this.overlay.className = this.overlay.className.replace(" z-open", "");

        /*
            * Listen for CSS transitionend event and then
            * Remove the nodes from the DOM
        */
        this.modal.addEventListener(this.transitionEnd, function() {
            _.modal.parentNode.removeChild(_.modal);
        });
        this.overlay.addEventListener(this.transitionEnd, function() {
            if(_.overlay.parentNode) _.overlay.parentNode.removeChild(_.overlay);
        });
    }
    
    function transitionSelect() {
        var el = document.createElement("div");
        if (el.style.WebkitTransition) return "webkitTransitionEnd";
        if (el.style.OTransition) return "oTransitionEnd";
            return 'transitionend';
    }
    //private
    function build(){
        var content, contentHolder, docFrag;

        /*
            * If content is an HTML string, append the HTML string.
            * If content is a domNode, append its content.
        */
        if (typeof this.options.content === "string") {
            content = this.options.content;
        } else {
            content = this.options.content.innerHTML;
        }
        //console.log(this.options.content);
        
        
        docFrag = document.createDocumentFragment();
        this.modal = document.createElement('div');
        if (this.options.containerTag == 'iframe'){
            this.options.className += " iframe"
        } else if (this.options.containerTag == 'video'){
            this.options.className += " video"
        }
        this.modal.className = 'z-modal ' + this.options.className;
        this.modal.style.maxWidth = this.options.maxWidth;
        this.modal.style.maxHeight = this.options.maxHeight;
        this.modal.style.width = this.options.width;
        this.modal.style.position = "fixed";
        this.container = document.createElement(this.options.containerTag);
        
        if (this.options.closeButton === true) {
            this.closeButton = document.createElement("i");
            this.closeButton.className = "fa fa-times z-close z-button";
            this.modal.appendChild(this.closeButton);
        }
        if (this.options.overlay === true) {
            this.overlay = document.createElement("div");
            this.overlay.className = "z-overlay";
            docFrag.appendChild(this.overlay);
        }
        if (this.options.containerTag == 'iframe'){
            this.iframe = document.createElement('iframe');
            this.iframe.src = content;
            this.iframe.scrolling = "no";
            this.iframe.height = this.options.height;
            this.iframe.id="modal-iframe";
            this.iframe.allowtransparency="true";
            this.iframe.style="background: #FFFFFF;"
            contentHolder = this.iframe;
        }else if (this.options.containerTag == 'video') {
            this.video = document.createElement('video');
            this.video.controls = 'controls';
            this.video.autoplay = 'autoplay'
            this.video.volume = 0.5;
            var sourceMP4 = document.createElement("source"); 
            sourceMP4.type = "video/mp4";
            sourceMP4.src = content;
            this.video.appendChild(sourceMP4);
            contentHolder = this.video;
        }else{
            contentHolder = document.createElement(this.options.containerTag);
            contentHolder.className = "z-content";
            contentHolder.innerHTML = content;
        }
        this.modal.appendChild(contentHolder);
        
        docFrag.appendChild(this.modal);
        document.body.appendChild(docFrag);
        if (this.options.containerTag == 'iframe'){ 
            
        }
    }
    function initEvents(){
        if (this.closeButton){
            this.closeButton.addEventListener('click', this.close.bind(this));
        }
        if (this.overlay){
            this.overlay.addEventListener('click', this.close.bind(this));
        }
        if (this.iframe){
            this.iframe.addEventListener('load', resizeIframe(this.iframe));
        }
    }
    function extendDefaults(source, prop){
        var property;
        for (property in prop){
            if (prop.hasOwnProperty(property)){
                source[property] = prop[property];
            }
        }
        return source;
    }
    
    
}());

$(document).keyup(function(e) {
     if (e.keyCode == 27) { // escape key maps to keycode `27`
        if ($('.z-close').length > 0){
            $('.z-close').trigger('click');
        }
    }
});