'use strict';
/**
 * @param {string} name
 * @return {?}
 */
function getCookie(name) {
    /** @type {(Array<string>|null)} */
    var cookie = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"));
    return cookie ? decodeURIComponent(cookie[1]) : void 0;
}

/**
 * @param {string} name
 * @param {string} value
 * @param {number} duration
 * @return {undefined}
 */

function SetCookie(name, value, duration) {
    /** @type {!Date} */
    var semiannualPing = new Date;
    /** @type {string} */
    var cookie_string = name + "=" + escape(value) + ";";
    if (null != duration) {
        /** @type {string} */
        cookie_string = cookie_string + "expires=" + (new Date(semiannualPing.getTime() + 24 * duration * 60 * 60 * 1e3)).toGMTString() + ";";
    }
    /** @type {string} */
    cookie_string = cookie_string + "path=/;";
    /** @type {string} */
    document.cookie = cookie_string;
}

/** @type {boolean} */
var popup_anim = false;
/** @type {boolean} */
var clickonpop = false;
var print_href;
var printwindow;

/**
 * @param {number} canCreateDiscussions
 * @return {?}
 */

function show_popup_menu(canCreateDiscussions) {
    if (!canCreateDiscussions || !clickonpop) {
        var $examplesList = $(".main_menu_popup");
        return "none" == $examplesList.css("display") && 1 != canCreateDiscussions ? (popup_anim = true, $examplesList.slideDown(200, function() {
            /** @type {boolean} */
            popup_anim = false;
        })) : "none" == $examplesList.css("display") || popup_anim || $examplesList.slideUp(200), false;
    }
    /** @type {boolean} */
    clickonpop = false;
}

/**
 * @param {?} value
 * @return {?}
 */

function checkFloat(value) {
    return !isNaN(value);
}

/**
 * @param {?} value
 * @return {?}
 */

function checkInteger(value) {
    return !isNaN(value) && parseInt(value) == parseFloat(value);
}

/**
 * @param {!Object} parent_elem$jscomp$0
 * @return {undefined}
 */

function activateColorboxes(parent_elem$jscomp$0) {
    /** @type {string} */
    var cls$jscomp$0 = "cb_nonactive";
    /** @type {!Object} */
    var data$jscomp$32 = new Object;
    var set$jscomp$0 = void 0;
    var next$jscomp$0;
    for (next$jscomp$0 in set$jscomp$0 = null == parent_elem$jscomp$0 ? $("a." + cls$jscomp$0) : parent_elem$jscomp$0.find("a." + cls$jscomp$0), set$jscomp$0.each(function(index$jscomp$54) {
        try {
            eval($(this).attr("rev"));
            if (null == data$jscomp$32[$(this).attr("rel")]) {
                /** @type {!Array} */
                data$jscomp$32[$(this).attr("rel")] = new Array;
            }
            data$jscomp$32[$(this).attr("rel")].push(cs);
        } catch (e) {
        }
    }), data$jscomp$32) {
        var subset$jscomp$0 = set$jscomp$0.filter("a[rel='" + next$jscomp$0 + "']");
        subset$jscomp$0.each(function(i) {
            $(this).attr("href", data$jscomp$32[next$jscomp$0][i].href);
            $(this).removeClass(cls$jscomp$0);
            if (null != data$jscomp$32[next$jscomp$0][i].classname) {
                $(this).addClass(data$jscomp$32[next$jscomp$0][i].classname);
            }
        });
        data$jscomp$32[next$jscomp$0][0].href = void 0;
        data$jscomp$32[next$jscomp$0][0].classname = void 0;
        if (void 0 !== subset$jscomp$0.colorbox) {
            subset$jscomp$0.colorbox(data$jscomp$32[next$jscomp$0][0]);
        }
    }
}

/**
 * @param {?} hero_image
 * @return {undefined}
 */

function refreshCaptcha(hero_image) {
    if (null != hero_image) {
        /** @type {string} */
//        hero_image.src = "/vendor/dapphp/securimage/securimage_show.php?sid=" + uniqid();
    }
}

/**
 * @param {number} prefix
 * @param {?} more_entropy
 * @return {?}
 */

function uniqid(prefix, more_entropy) {
    var retId;
    if (void 0 === prefix) {
        /** @type {string} */
        prefix = "";
    }
    /**
     * @param {string} seed
     * @param {number} reqWidth
     * @return {?}
     */
    var formatSeed = function(seed, reqWidth) {
        return reqWidth < (seed = parseInt(seed, 10).toString(16)).length ? seed.slice(seed.length - reqWidth) : reqWidth > seed.length ? Array(reqWidth - seed.length + 1).join("0") + seed : seed;
    };
    return this.php_js || (this.php_js = {}), this.php_js.uniqidSeed || (this.php_js.uniqidSeed = Math.floor(123456789 * Math.random())), this.php_js.uniqidSeed++, retId = prefix, retId = retId + formatSeed(parseInt((new Date).getTime() / 1e3, 10), 8), retId = retId + formatSeed(this.php_js.uniqidSeed, 5), more_entropy && (retId = retId + (10 * Math.random()).toFixed(8).toString()), retId;
}

/**
 * @param {string} canCreateDiscussions
 * @return {undefined}
 */

function show_just_current(canCreateDiscussions) {
    SetCookie("show_just_current", canCreateDiscussions ? "1" : "0");
    /** @type {string} */
    var url = window.location.href;
    /** @type {number} */
    var hashIndex = url.lastIndexOf("/");
    if (!isNaN(parseInt(url.substr(hashIndex + 1)))) {
        /** @type {string} */
        url = url.substr(0, hashIndex);
    }
    if (url == window.location.href) {
        window.location.reload(true);
    } else {
        /** @type {string} */
        window.location.href = url;
    }
}

/**
 * @param {!Element} frame
 * @param {?} manifest
 * @param {string} url
 * @return {undefined}
 */

function printFrameContent(frame, manifest, url) {
    if (manifest) {
        var location = frame.contentWindow.location;
        print_href = url ? location.protocol + "//" + location.host + "/printimage?url=" + encodeURIComponent(location.href) : location.href;
        /** @type {(Window|null)} */
        printwindow = window.open(print_href, "print_popup", "width=500,height=500");
        if (!url) {
            setTimeout(check_state, 10);
        }
    } else {
        var win = frame.contentWindow;
        win.focus();
        win.print();
    }
}

/**
 * @return {undefined}
 */

function check_state() {
    if (printwindow.location.href == print_href && "complete" == printwindow.document.readyState) {
        printwindow.focus();
        printwindow.print();
    } else {
        setTimeout(check_state, 100);
    }
}

/**
 * @param {string} s
 * @param {string} t
 * @param {!Object} postBody
 * @return {undefined}
 */

function sendToFriend(s, t, postBody) {
    /** @type {string} */
    s = "//" + _settings.domain + s;
    if (null == postBody) {
        /** @type {string} */
        postBody = "";
    }
    if (null == t) {
        /** @type {string} */
        t = s;
    }
    /** @type {string} */
    window.location.href = "mailto:?subject=" + postBody + "&body=" + t;
}

/**
 * @param {?} msg
 * @return {undefined}
 */

function custom_alert(msg) {
    jAlert(msg, _settings.message);
}

/**
 * @param {?} msg
 * @param {!Function} receiverOptions
 * @return {undefined}
 */

function custom_confirm(msg, receiverOptions) {
    jConfirm(msg, _settings.warning, receiverOptions);
}

/**
 * @param {!Object} e
 * @param {?} node
 * @return {?}
 */

function checkField(e, node) {
    var value = e.value.replace(",", ".");
    return checkFloat(value) ? parseFloat(value) < 0 ? (custom_alert(_settings.error_number_pos), e.focus(), false) : !(!node && !checkInteger(value)) || (custom_alert(_settings.error_number_int), e.focus(), false) : (custom_alert(_settings.error_number), e.focus(), false);
}

var changecity_url = void 0;
var changecity_id_rest = void 0;

/**
 * @param {?} name
 * @param {(Error|string)} res
 * @param {number} rej
 * @return {?}
 */

function changeCity(name, res, rej) {
    return changecity_url = res, changecity_id_rest = rej, custom_confirm(_settings.change_city + name, changeCity_callback), false;
}

/**
 * @param {?} canCreateDiscussions
 * @return {undefined}
 */

function changeCity_callback(canCreateDiscussions) {
    if (canCreateDiscussions) {
        /** @type {string} */
        document.location.href = changecity_url + "?id_res=" + encodeURIComponent(changecity_id_rest) + "#contact";
    }
}

/**
 * @return {undefined}
 */

function showSearch() {
    if ("plus" == document.getElementById("search_a").className) {
        $("#search_param").slideDown();
        document.getElementById("search_label").innerHTML = _settings.com_hide_ext;
        /** @type {string} */
        document.getElementById("search_a").className = "minus";
        SetCookie("show_advanced_search", "1");
    } else {
        $("#search_param").slideUp();
        document.getElementById("search_label").innerHTML = _settings.com_show_ext;
        /** @type {string} */
        document.getElementById("search_a").className = "plus";
        SetCookie("show_advanced_search", "0");
    }
}

/**
 * @param {?} canCreateDiscussions
 * @return {?}
 */

function getImageUploadErrorMessage(canCreateDiscussions) {
    /** @type {string} */
    var styleClass = "";
    switch(canCreateDiscussions) {
        case 1:
        case 2:
        case 13:
            styleClass = _settings.upload_error_too_big;
            break;
        case 10:
            styleClass = _settings.upload_error_wrong_type;
            break;
        default:
            styleClass = _settings.upload_error;
    }
    return styleClass;
}

/**
 * @param {string} server
 * @param {?} room
 * @param {number} page
 * @param {!Object} params
 * @return {?}
 */

function updateRating(server, room, page, params) {
    return jQuery.ajax({
        type : "POST",
        url : server + "/update-rating",
        data : {
            id_brand : room,
            page : page,
            type : params
        }
    }), false;
}

/**
 * @return {?}
 */

function getUrlVars() {
    var m;
    /** @type {!Array} */
    var params = [];
    /** @type {!Array<string>} */
    var row = window.location.href.slice(window.location.href.indexOf("?") + 1).split("&");
    /** @type {number} */
    var CR_index = 0;
    for (; CR_index < row.length; CR_index++) {
        /** @type {!Array<string>} */
        m = row[CR_index].split("=");
        params.push(m[0]);
        /** @type {string} */
        params[m[0]] = m[1];
    }
    return params;
}

/**
 * @param {!Object} names
 * @return {?}
 */

function reverseObjectKeys(names) {
    var glyph_name_to_id = {};
    var i;
    for (i in names) {
        /** @type {string} */
        glyph_name_to_id[names[i]] = i;
    }
    return glyph_name_to_id;
}

/**
 * @param {string} type
 * @param {!Object} elem
 * @param {?} func
 * @return {undefined}
 */

function addEvent(type, elem, func) {
    if (elem.addEventListener) {
        elem.addEventListener(type, func, false);
    } else {
        if (elem.attachEvent) {
            elem.attachEvent("on" + type, func);
        } else {
            elem[type] = func;
        }
    }
}

/**
 * @return {undefined}
 */

function ajax_loading_start() {
    var tmpImage = $("<div>", {
        class : "ds-loading"
    });
    $(tmpImage).css("top", $(window).height() / 2 - $(tmpImage).height() / 2).css("left", $(document).width() / 2 - $(tmpImage).width() / 2);
    $("body").append(tmpImage);
}

/**
 * @return {undefined}
 */

function ajax_loading_stop() {
    $(".ds-loading").detach();
}

/**
 * @param {?} name
 * @return {?}
 */

function validateEmail(name) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(name);
}

/**
 * @param {string} s
 * @param {string} str
 * @return {?}
 */

function rtrim(s, str) {
    str = str ? str.replace(/([\[\]\(\)\.\?\/\*\{\}\+\$\^:])/g, "$1") : " s\u00a0";
    /** @type {!RegExp} */
    var n = new RegExp("[" + str + "]+$", "g");
    return s.replace(n, "");
}

Share = {
    vkontakte : function(clientId, name, e, i) {
        if (url = "//vkontakte.ru/share.php?", url = url + ("url=" + encodeURIComponent(clientId)), url = url + ("&title=" + encodeURIComponent(name)), lentext = 249, i.length > lentext) {
            textar = i.split(" ");
            /** @type {string} */
            i = "";
            /** @type {number} */
            var nx = 0;
            for (; nx < textar.length && (str = i + textar[nx], str.length < lentext); nx++) {
                /** @type {string} */
                i = i + (textar[nx] + " ");
            }
        }
        /** @type {string} */
        url = url + ("&description=" + encodeURIComponent(i));
        /** @type {string} */
        url = url + ("&image=" + encodeURIComponent(e));
        /** @type {string} */
        url = url + "&noparse=true";
        Share.popup(url);
    },
    odnoklassniki : function(clientId, callback) {
        /** @type {string} */
        url = "//www.odnoklassniki.ru/dk?st.cmd=addShare";
        /** @type {string} */
        url = url + ("&st._surl=" + encodeURIComponent(clientId));
        Share.popup(url);
    },
    facebook : function(link, app, options, data) {
        if (url = "//www.facebook.com/sharer.php?s=100", url = url + ("&p[title]=" + encodeURIComponent(app)), lentext = 299, data.length > lentext) {
            textar = data.split(" ");
            /** @type {string} */
            data = "";
            /** @type {number} */
            var i = 0;
            for (; i < textar.length && (str = data + textar[i], str.length < lentext); i++) {
                /** @type {string} */
                data = data + (textar[i] + " ");
            }
        }
        /** @type {string} */
        url = url + ("&p[summary]=" + encodeURIComponent(data));
        /** @type {string} */
        url = url + ("&p[url]=" + encodeURIComponent(link));
        /** @type {string} */
        url = url + ("&p[images][0]=" + encodeURIComponent(options));
        Share.popup(url);
    },
    twitter : function(link, value) {
        ptitlear = value.split(" ");
        /** @type {string} */
        value = "";
        /** @type {number} */
        var i = 0;
        for (; i < ptitlear.length && (str = value + ptitlear[i], str.length < 245); i++) {
            /** @type {string} */
            value = value + (ptitlear[i] + " ");
        }
        /** @type {string} */
        url = "//twitter.com/share?";
        /** @type {string} */
        url = url + ("text=" + encodeURIComponent(value));
        /** @type {string} */
        url = url + ("&url=" + encodeURIComponent(link));
        /** @type {string} */
        url = url + ("&counturl=" + encodeURIComponent(link));
        Share.popup(url);
    },
    googleplus : function(args, callback) {
        /** @type {string} */
        url = "https://plus.google.com/share?";
        /** @type {string} */
        url = url + ("url=" + encodeURIComponent(args));
        /** @type {string} */
        url = url + "&hl=ru";
        Share.popup(url);
    },
    popup : function(url) {
        window.open(url, "", "toolbar=0,status=0,width=626,height=436");
    }
};
