var tabsID = [];//["tab_features","tab_otzyvy","tab_news"];
var linksID = [];//["link_features", "link_otzyvy","link_news"];
var stopTime = 250;
var size = 0;
var currentIndex = 0;
var canLoad = true;
var textMore = "";
var textLess = "";
function addTab(tab, link){
  tabsID[size] = tab;
  linksID[size] = link;
  size++;
}
function setTextes(more, less){
  textMore = more;
  textLess = less;
}
function isScrolledIntoView(elem)
{
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();
    if ($(elem).offset() == undefined) return false;
    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();
    return (elemTop <= docViewBottom);
}

function checkVisible(elm) {
  var rect = elm.getBoundingClientRect();
  var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
  return !(rect.bottom < 200 || rect.top - viewHeight >= 100);
}
function getVisible() {    
    var $el = $('footer'),
        scrollTop = $(this).scrollTop(),
        scrollBot = scrollTop + $(this).height(),
        elTop = $el.offset().top,
        elBottom = elTop + $el.outerHeight(),
        visibleTop = elTop < scrollTop ? scrollTop : elTop,
        visibleBottom = elBottom > scrollBot ? scrollBot : elBottom;
    return (visibleBottom - visibleTop);
}
$(document).ready(function(){

    var tab;
    //console.log($(".nav-anchor[data-scroll='" + tab + "']"));
    try {
        var url_string = window.location.href;
    var url = new URL(url_string);
    tab = url.searchParams.get("tab");
  // код ...

} catch (err) {

  console.log(err);

}
    if (tab != undefined){
        setTimeout(function(){
            if (tab == 'novosti'){
                tab = "news";
            }
            //console.log('tab=' + tab);
            $(".nav-anchor[data-scroll='" + tab + "']").find('span').trigger('click'); 
        }, 500);
                setTimeout(function(){
            if (tab == 'novosti'){
                tab = "news";
            }
            //console.log('tab=' + tab);
            $(".nav-anchor[data-scroll='" + tab + "']").find('span').trigger('click'); 
        }, 1000);
    }
    
});

$(window).on('scroll', function() {
    
    //footer push-up
    if($('.left-fixed-menu').length > 0){
        
        var f = $('footer').height() + 32 + 44;
        var wH = $(window).height() - f;
        var lfm = $('.left-fixed-menu').height();
        if (lfm > wH){
        var vis = getVisible();
        if (vis > 0){
            vis += 16;
            if ($(window).width() > 1024){
                $('.left-fixed-menu').css('bottom', vis + "px").css('top', 'auto');
            }
        } else {
            var y = $(window).scrollTop();
            if ($(window).width() > 1024){
            if (y > 200){
                $('.left-fixed-menu').css('bottom', "auto").css('top', '66px');
            } else {
                $('.left-fixed-menu').css('bottom', "auto").css('top', "16px");
            }
            }
        }
        }
    }
  if (currentIndex < size){
      //alert(isScrolledIntoView($('#' + tabsID[currentIndex])));
     // console.log("canload: " + canLoad);
      
      if (canLoad)
        for (var i = 0; i < size; i++){
            //console.log(checkVisible(document.getElementById(tabsID[i])) + ":" + tabsID[i]);
            if (checkVisible(document.getElementById(tabsID[i])) && $('#' + tabsID[i]).find('.loading_div').length > 0 && !$('#' + tabsID[i]).hasClass('loaded')){
               // if ($('#' + tabsID[i]).hasClass('loaded')){
                    
                //} else {
                if (tabsID[i] != "tab_news"){
                    $('#' + linksID[i]).trigger('click', true);
                    $('#' + tabsID[i]).css('min-height', '100px');
                } else {
                    //if (){
                        $('#' + linksID[i]).trigger('click', true);
                        $('#' + tabsID[i]).css('min-height', '100px');
                    //}
                }
                canLoad = false;
                setTimeout(function(){
                    canLoad = true;
                }, stopTime);
                //}
            }  
        }
      
    // if(isScrolledIntoView($('#' + tabsID[currentIndex])) && !$('#' + tabsID[currentIndex]).hasClass('loaded') && canLoad)
    // {
    //     console.log(document.getElementById(linksID[currentIndex]));
    //     if (checkVisible(document.getElementById(linksID[currentIndex]))){
    //   canLoad = false;
    //     $('#' + linksID[currentIndex]).trigger('click', true);
    //     $('#' + tabsID[currentIndex]).addClass('loaded').css('min-height', '50px');
    //     setTimeout(function(){
    //       currentIndex++;
    //       canLoad = true;
    //     }, stopTime);
    // }
    // }
}
});

jQuery(function(){
    createSticky(jQuery(".l-menu"));
    if ($('.calculator-modal').length > 0){
        createStickyRight(jQuery(".calculator-modal"));
    }
    if ($('.left-fixed-menu').length > 0){
        createLeftSticky(jQuery(".left-fixed-menu"));
        $('.l-site--left').css('visibility', 'hidden').addClass('hidden-small');
        if ($('.advanced').length > 0)
            $('nav .toggle').before('<div class="filters-toggle"><i class="fa fa-filter" aria-hidden="true"></i></div>');
        $('.l-site--right').addClass('full-flex');
        if ($('.advanced').length <= 0)
            $('.l-site--center').addClass('full-padding');
    
    
          var leftW = $('.l-site--left').width();
        var marginR = parseInt($('.l-site--left').css('margin-right')) + parseInt($('.l-site--center').css('margin-left'));
        
        $('.left-fixed-menu').css({
           width: leftW + "px",
           marginLeft:  (-1)*(leftW + marginR) + "px"
        });

        if ($(".ext__selected__item").length > 0) {
            $(".fa-filter").css({color: "rgb(80, 191, 252)"});
        }
    
    
    }
    $(".nav-anchor").on('click', function(event, par) {
        if (par != true){
	    var find = $(this).attr('data-scroll');
	    //console.log("#" + find);
	    canLoad = false;
        $('html, body, document').animate({
            scrollTop: $("#" + find).offset().top - 66
        }, 400, function(){
            //let finishing animation
            setTimeout(function(){canLoad = true;}, 250)
        });
        }
    });
    $('.tab_ob>.tab_ob').on('click', '.pages a', function(){
       var $anchor = $(this).parent().parent();
       if ($anchor.parent().hasClass('.tab_ob')){
           $anchor = $anchor.parent();
       }
      // console.log($anchor);
        $('html, body, document').animate({
            scrollTop: $anchor.offset().top - 130
        }, 800);
    });
    $('.tab_ob').on('click', '.pages a', function(){
       var $anchor = $(this).parent().parent();
       if ($anchor.parent().hasClass('.tab_ob')){
           $anchor = $anchor.parent();
       }
      // console.log($anchor);
        $('html, body, document').animate({
            scrollTop: $anchor.offset().top - 130
        }, 800);
    });
    
});

function createSticky(sticky) {
    if (typeof sticky != "undefined" && sticky.length > 0) {

        var pos = sticky.offset().top ,
            win = jQuery(window);

        
        stickySwitch(win, sticky, pos, "stickyhead");   
        win.on("scroll", function() {
            stickySwitch(win, sticky, pos, "stickyhead");       
        });         
    }
}
function createStickyRight(sticky) {
    if (typeof sticky != "undefined" && sticky.length > 0) {

        var pos = $('.l-menu').offset().top ,
            win = jQuery(window);

        
        stickySwitch(win, sticky, pos, "stickyhead-right");   
        win.on("scroll", function() {
            stickySwitch(win, sticky, pos, "stickyhead-right");    
        });         
    }
}

function stickySwitch(win, sticky, pos, className) {
    if(win.scrollTop() > pos ) {
        sticky.addClass(className);
    } else {
        sticky.removeClass(className);
    }       
}


window.onscroll = function() { };
window.onload = function(){
}
function createLeftSticky(sticky) {
    if (typeof sticky != "undefined" && sticky.length > 0) {

        var pos = sticky.offset().top ,
            win = jQuery(window);
        var fullH = $('.l-toolbar').parent().height();
        var redH = $('.nav').height() + 16;
        var minTop = fullH + 16;
        
        stickySwitch(win, sticky, pos, "left-stickyhead");
        win.on("scroll", function() {
        var leftW = $('.l-site--left').width();
        var marginR = parseInt($('.l-site--left').css('margin-right')) + parseInt($('.l-site--center').css('margin-left'));
        
        $('.left-fixed-menu').css({
           width: leftW + "px",
           marginLeft:  (-1)*(leftW + marginR) + "px"
        });

        stickySwitch(win, sticky, pos, "left-stickyhead");
        });         
    }
}
