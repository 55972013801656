$(document).ready(function(){
    var blocked = 0;
$('.cart-toggle').live('click', function(){
   var $calc = $('.calculator-modal');
   blocked = 1;
   console.log($calc);
   if ($calc.hasClass('open')){
       $calc.css('display', 'none').removeClass('open');
   } else {
       $calc.css('display', 'block').addClass('open');
   };
   setTimeout(function(){blocked = 0},150)
});
    $('.dropdown__text').on('click', function(){
        if ($(window).width() > 1240) return;
       var $container = $(this).parent();
       if ($container.hasClass('list-show')){
           $container.removeClass('list-show').find('.dropdown__list').css('display', 'none');;
           return;
       } else {
           $('.dropdown__text').next().css('display', 'none');
           $('.dropdown__text').parent().removeClass('list-show');
           $container.addClass('list-show').find('.dropdown__list').css('display', 'block');
       }
    });
    $('.filters-toggle').live('click', function(){
        if ($(this).hasClass('show-f')){
            $(this).removeClass('show-f');
            $('.advanced').hide();
        } else {
            $(this).addClass('show-f');
            $('.advanced').show();
        }
    });
    $('#tab_otzyvy').on('click', 'a.more', function(e){
        e.preventDefault();
        //console.log('prevent');
        $(this).next().css('display', 'inline');
        $(this).prev().css('display', 'none');
        $(this).css('display', 'none');
    });
    $('.boxplus-close, .boxplus-background').on('click', function(){
       $('html, body').css('overflow-y', 'auto'); 
    });
    
    $('.review__text').on('click', '.more', function(e){
       e.preventDefault();
       $(this).next().show();
       $(this).prev().hide();
    });
$('#login').on('click', function(e){
    // e.preventDefault();
    // return false;
});

   $('.calendar__mobile').on('click', function(){
       if(!$(this).next().hasClass('calendar-open')){
            $(this).next().addClass('calendar-open').show(); 
       } else {
          $(this).next().removeClass('calendar-open').hide(); 
       }
   });
  $(document).click(function(e) {
        var container0 = $(".l-calendar");
    if (!container0.is(e.target) && container0.has(e.target).length === 0) {
        if (container0.find('.calendar').hasClass('calendar-open') && !$(e.target).hasClass('fa-angle-right') && !$(e.target).hasClass('fa-angle-left'))
            container0.find('.calendar').removeClass('calendar-open').hide();
    }
            var container0c = $(".calculator-modal");
    if (!container0c.is(e.target) &&  container0c.has(e.target).length === 0) {
            if ($(".calculator-modal").hasClass('open') && blocked == 0){
                $(".calculator-modal").removeClass('open').hide();
                console.log(e.target);
            }
    }
    var container00 = $(".js-copy");
    if (!container00.is(e.target) && container00.has(e.target).length === 0) {
            $('.copy-link__helper').hide();
    }
    
    var container = $(".dropdown");
    var toggle = $(".nav");
    if (!toggle.is(e.target) && toggle.has(e.target).length === 0 && $(window).width() < 1240) {
            if ($('.nav__toggle').hasClass('menu-open')){
                $('.nav__toggle').slideUp(300).removeClass('menu-open');
                $('body, html').css('overflow-y', 'auto');
            }
    }
    var container2 = $(".bar-list__select");
    if (!container2.is(e.target) && container2.has(e.target).length === 0) {
        container2.next().slideUp(300);
    }
    var container3 = $(".form--inline, .mobile");
    if (!container3.is(e.target) && container3.has(e.target).length === 0 && $(window).width() < 993) {
        $(".form--inline").slideUp(300);
    }
});
    
    $('.toggle').on('click', function(){
        if ($('.advanced').length > 0){
            $('.advanced').hide();
            $('.filters-toggle').removeClass('show-f');
        }
       $(this).next().slideToggle(300).toggleClass('menu-open'); 
       if ($(this).next().hasClass('menu-open')){
           $('body, html').css('overflow-y', 'hidden');
       }else{
           $('body, html').css('overflow-y', 'auto');
       }
    });
    
    $('.bar-list__select').on('click', function(){
       $(this).next().slideToggle(300); 
    });
    $('.mobile').on('click', function(){
       $(this).next().slideToggle(300); 
    });
});


  function Dropdown($items) {
    if (!$items) { return; }
    $items.each(function () {
      var $this = $(this);
      var $next  = $this.next();
      $this.on('click', function(){
        $next.slideToggle(300);
      });
    });
    return $items;
  }
  var $items = "";
  $(document).ready(function(){
     $items = $('.dropdown-mobile');
    Dropdown($items);
  });
	

  $(document).on('click', function(event){
    if (!$items) { return; }
    
    var $self = $(event.target);

    $items.each(function(){
        var target = $(this).data('target');
        if ($self.data('target') != target && $self.parent().data('target') != target && $(window).width() < 1024){
            $(this).next().slideUp(300);
        }
    })
  });
$(window).on('resize', function(){
    if ($(window).width() > 1023){
        //$('.bar-list, .form--inline').css('display', 'block');
    }
})